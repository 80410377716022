<template>
  <users-data-table-component
      endpoint="users"
      type="users"/>
</template>
<script>

export default {
  name: "Ideas"
}
</script>